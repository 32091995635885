.first{
  img {
    width:100%;
  }
}

html {
  background-color: #000;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


@media (max-width: 820px) {
  .player-wrapper {
    width:100%;
    top:25vh;
  }
}

@media (max-width: 1025px) {
  .player-wrapper {
    width:100%;
    top:25vh;
  }
}