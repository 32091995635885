.first-contact{
    img {
      width:100%;
    }
  }



div .text-contact p{
    position: absolute;
    width:43vw;
    height:100vw;
    top: 33px;
    z-index: 1;
    color:rgb(255, 255, 255);
    font-size: 1.5em;
    padding:3%;
    padding-top:25vh;
    text-align: justify;
    right:0;
    background-color: rgba(0, 0, 0, 0.6);
    
    font-family: "Thicccboi";
    /*border-top-left-radius: 50px;*/
}

div .text-comp p{
    position: absolute;
    width:35vw;
    top:7vh;;
    z-index: 1;
    color:rgb(0, 0, 0);
    font-size: 2em;
    font-weight: bold;
    padding:3%;
    padding-left: 7%;
    text-align: right;
    font-family: "Thicccboi";
    border-top-right-radius:50px;
}

@media (max-width: 820px) {

    div .text-contact p{
        display:block;
        flex-direction: column;
        width:93vw;
      
        top:50vh;
        z-index: 1;
        color:rgb(255, 255, 255);
        font-size: 1.6em;
        padding:3%;
        text-align: justify;
        padding-top: 5vh;
        background-color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        overflow-y: auto;
        margin-top: 3%;
    }

    div .text-comp p{
        display: block;
        
        width:95vw;
        
        z-index: 1;
        color:rgb(255, 255, 255);
        font-size: 1.6em;
        padding:3%;
        text-align: center;
        border-top-right-radius:50px;
        font-weight: lighter;
        padding-bottom: 15px;
        margin-bottom: 45px;
    }

    h1{
        font-size: 1.4em;;
    }

    .first-contact img { 
        opacity: 40%;
    }
}


@media (max-width: 400px) {

    div .text-contact p{
        display:block;
        flex-direction: column;
        width:93vw;
      
        top:70vh;
        z-index: 1;
        color:rgb(255, 255, 255);
        font-size: 1.6em;
        padding:3%;
        text-align: justify;
        padding-top: 5vh;
        background-color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
        overflow-y: auto;
        margin-top: 3%;
    }

    div .text-comp p{
        display: block;
        
        width:95vw;
        
        z-index: 1;
        color:rgb(255, 255, 255);
        font-size: 1.6em;
        padding:3%;
        text-align: center;
        border-top-right-radius:50px;
        font-weight: lighter;
        padding-bottom: 15px;
        margin-bottom: 45px;
    }

    h1{
        font-size: 1.4em;;
    }

    .first-contact img { 
        opacity: 40%;
    }
}