.nav-bar {
  background: rgba(0, 0, 0, 0.4); /* black with 80% transparency */
  width: 100%;
  height: 50px; /* or whatever you want */
  position: fixed;
  display:flex;
  font-family: "Thicccboi";
  align-items: center;
  z-index: 1; /* Placez votre conteneur au dessus de tout */
}
.nav-bar nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 0 20px; /* added some left and right padding */
}

.nav-bar nav a {
    font-size: 25px;
  color: #f0f0f0; /* your desired text color */
  display: block;
    user-select: none;
    position: relative;
    text-decoration: none;
    list-style-type: none;
      
    }

    .socio {
      position: relative;
      
      display: 'grid'; 
      grid-template-columns: [col-1] 200px repeat(4, 1fr) [col-6];
     display:flex;
    }

    .nav-bar li{
      list-style-type: none;
      }


.nav-bar nav a:hover {
  color: #ffd700; /* your desired hover color */
  svg { opacity: 1; }
      &:after {
        opacity: 1;
      }
    }

    



    .categories {
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px; /* ajustez la valeur à votre convenance */
      margin: 0 auto;
      padding-left: 10%;
      gap:20px;
    }


/* Add media query for 1280 devices  */

@media (max-width: 1280px) {
  .nav-bar nav a {
    font-size: 20px;
  color: #f0f0f0; /* your desired text color */
  display: block;
    user-select: none;
    position: relative;
    text-decoration: none;
    list-style-type: none;
      
    }



  


  .socio {
    position: relative;
    flex-direction: column;
    display: 'grid'; 
    grid-template-columns: [col-1] 200px repeat(4, 1fr) [col-6];
   top: 70px;
  }

}


/* Add media query for 1024 devices  */

@media (max-width: 1024px) {
  .nav-bar nav a {
    font-size: 15px;
  color: #f0f0f0; /* your desired text color */
  display: block;
    user-select: none;
    position: relative;
    text-decoration: none;
    list-style-type: none;
      
    }



  .categories {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-left: 0%;
    gap:5px;
  }


  .socio {
    position: relative;
    flex-direction: column;
    display: 'grid'; 
    grid-template-columns: [col-1] 200px repeat(4, 1fr) [col-6];
   top: 70px;
  }

}


/* Add media query for mobile devices (portrait mode) */

@media (max-width: 820px) {

  

  .nav-bar {
    position: relative;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.4); /* black with 80% transparency */
    width: 100%;
    height: 70px; /* or whatever you want */
    z-index: 1; /* Placez votre conteneur au dessus de tout */
  }
  .nav-bar .nav {
    margin-left: 0;
    padding-top: 10px;
  }
  .nav-bar  {
    display: block;
    position: relative;
    cursor: pointer;
  }
  .nav-bar  {
    width: 100%;
    display: flex;
    
  }
  .categories {
    position: relative;
    display: flex;
    flex-direction: row;
    
  }
  
    .socio {
    
      flex-direction:column;
      align-self: center;
      display: block;
      flex-wrap: wrap;
      top:75vh;
      padding:15px;
     
    
  }
}

/* Hide unnecessary elements on small screens */
@media (max-width: 480px) {
  .nav-bar .categories {
    
  }

  .socio {
    
    flex-direction:row;
    align-self: center;
    display: block;
    flex-wrap: wrap;
    top:75vh;
    padding:15px;
   
  }
  
  .about-link img{
    
    width: 10px;
  }
}




.logo {
 
  
  font-size: 25px;
  color: #f0f0f0; /* your desired text color */
  display: block;
      position: relative;
      text-decoration: none;
      float:left;
      left:7px;
      margin-right: 0 auto;
   /* added some left and right padding */
  user-select: none;
    

}
@media (max-width: 820px) {
.logo {
 
  
  font-size: 25px;
  color: #f0f0f0; /* your desired text color */
  display: block;
      position: relative;
      text-decoration: none;
      float:left;
      left:0px;
      margin-right: 0 auto;
   /* added some left and right padding */
  user-select: none;
    

}
}

.logo img{
  height:100%;
}

.nav-bar .pho-link ,
.nav-bar .cam-link ,
.nav-bar .anim-link ,
.nav-bar .gen-link {
  
  
}

.nav-bar .pho-link a,
.nav-bar .cam-link a,
.nav-bar .anim-link a,
.nav-bar .gen-link a {
  
}


.nav-bar .pho-link-selected a,
.nav-bar .cam-link-selected a,
.nav-bar .anim-link-selected a,
.nav-bar .gen-link-selected a{
  color:#ffd700;
  
}






.about-link {
width:15px;
display:block;
float:left;
padding-left:15px;
text-decoration: none;
list-style-type: none;
}

.about-link a{
text-decoration: none;
list-style-type: none;
}



.about-link:hover{
  color:#ffd700;
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
  color: white;
  opacity: 0.2;
}

svg:not(:root).svg-inline--fa:hover, svg:not(:host).svg-inline--fa:hover {
  overflow: visible;
  box-sizing: content-box;
  color: white;
  opacity: 1;
}
