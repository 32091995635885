.first{
  img {
    width:100%;
  }
}

html {
  background-color: #000;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


div .text-block {
  position: absolute;
  bottom: 30vh; // or some other value to position it above the slider
  
  z-index: 1;
  color:rgb(255, 255, 255);
  font-size: 1.6em;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0px 30px 0px 0px;
}


@media (max-width: 1025px) {
  div .text-block {
  position: absolute;
 top:70vh; // or some other value to position it above the slider
  
  z-index: 1;
  color:red($color: #000000);
  font-size: 1em;
  font-weight: light;
  padding-left: 5;
  padding-right: 5px;
  background-color: "rgba(0, 0, 0, 0.6)";
  border-radius: '0px 30px 0px 0px';
  }
}

@media (max-width: 820px) {
  div .text-block {
  position: absolute;
 top:70vh; // or some other value to position it above the slider
  
  z-index: 1;
  color:red($color: #000000);
  font-size: 1em;
  font-weight: light;
  padding-left: 5;
  padding-right: 5px;
  background-color: "rgba(0, 0, 0, 0.6)";
  border-radius: '0px 30px 0px 0px';
  }
}









div .text-block {
  position: absolute;
  bottom: 35vh; // or some other value to position it above the slider
  
  z-index: 1;
  color:rgb(255, 255, 255);
  font-size: 1.6em;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0px 30px 0px 0px;
}

@media (max-width: 1025px) {
  div .text-block {
  position: absolute;
  bottom: 50; // or some other value to position it above the slider
  min-height: 5vh;
  z-index: 1;
  color:red($color: #000000);
  font-size: 1,5em;
  font-weight: light;
  padding-left: 20px;
  padding-right: 20px;
  background-color: "rgba(0, 0, 0, 0.6)";
  border-radius: '0px 30px 0px 0px';
  }
}

@media (max-width: 820px) {
  div .text-block {
      position: absolute;
  bottom: 50; // or some other value to position it above the slider
  
  z-index: 1;
  color:red($color: #000000);
  font-size: 1em;
  font-weight: light;
  padding-left: 5px;
  padding-right: 15px;
  background-color: "rgba(0, 0, 0, 0.6)";
  border-radius: '0px 30px 0px 0px';
  }
}