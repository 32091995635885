.first{
  img {
    width:100%;
    text-decoration: none;
  }
}

text, p{
  color:rgb(184, 181, 181);
  font-family: "Thicccboi";
}

div .text-accueil{
 position: relative;
  justify-content:space-around;
  align-items: center;
  top:20vh;
  height: 50vh;
  width:70vw;
  font-family: 'Thicccboi';
  font-Size:2rem;
  color:white;
  padding: 5px;
 padding-left:15vw;
 padding-right:15vw;
}

.text-accueil{
  
  justify-content:space-around;
  align-items: center;
  height: 50vh;
  width:98vw;
  font-family: 'Thicccboi';
  font-Size:2rem;
  color:rgb(202, 202, 202);
  padding: 5px;
  text-decoration: none;
}

.text-accueil a:visited {
  color: rgb(221, 221, 221);
  text-decoration: none;
}

div .text-accueil a {
  display: relative;
  text-decoration: none;
  font-weight: bold;
}


div .grid-container{
  display: flex;
  
   height: 50vh;
}

div .pho_acc{
  
  width:25vw;
}

.pho_acc {
  position: relative;
  &::after {
    content: "PHOTOGRAPHIE";
    display: block;
    position: absolute;
    bottom: 0; /* adjust the positioning */
    left: 50%; /* center it horizontally */
    color: white;
    font-family: "Thicccboi";
    font-size: 3rem; /* use a rem unit for better scalability */
    font-weight: bold;
    text-align: center;
    align-items: center;
    transform: translateX(-50%); /* adjust the vertical positioning */
    width: 100%; /* make it full-width */
    height: 100px; /* set a fixed height */
    bottom:28%;
    background-color: transparent; /* add a white overlay */
    opacity: 0; /* set initial opacity to 0 */
    transition: opacity .3s; /* add transition effect for opacity */
  }
  &:hover::after {
    opacity: 1; /* set opacity to 1 on hover */
  }
}
.pho_acc a img {
  transition: opacity .3s;
}
.pho_acc a img:hover {
  opacity: 0.2;
}


div .video_acc{
  
  width:25vw;
}

.video_acc {
  position: relative;
  &::after {
    content: "FILMS";
    display: block;
    position: absolute;
    bottom: 0; /* adjust the positioning */
    left: 50%; /* center it horizontally */
    color: white;
    font-family: "Thicccboi";
    font-size: 3rem; /* use a rem unit for better scalability */
    font-weight: bold;
    text-align: center;
    align-items: center;
    transform: translateX(-50%); /* adjust the vertical positioning */
    width: 100%; /* make it full-width */
    height: 100px; /* set a fixed height */
    bottom:28%;
    background-color: transparent; /* add a white overlay */
    opacity: 0; /* set initial opacity to 0 */
    transition: opacity .3s; /* add transition effect for opacity */
  }
  &:hover::after {
    opacity: 1; /* set opacity to 1 on hover */
  }
}
.video_acc a img {
  transition: opacity .3s;
}
.video_acc a img:hover {
  opacity: 0.2;
}

div .anim_acc{
  
  width:25vw;
}

.anim_acc {
  position: relative;
  &::after {
    content: "CG ART";
    display: block;
    position: absolute;
    bottom: 0; /* adjust the positioning */
    left: 50%; /* center it horizontally */
    color: white;
    font-family: "Thicccboi";
    font-size: 3rem; /* use a rem unit for better scalability */
    font-weight: bold;
    text-align: center;
    align-items: center;
    transform: translateX(-50%); /* adjust the vertical positioning */
    width: 100%; /* make it full-width */
    height: 100px; /* set a fixed height */
    bottom:28%;
    background-color: transparent; /* add a white overlay */
    opacity: 0; /* set initial opacity to 0 */
    transition: opacity .3s; /* add transition effect for opacity */
  }
  &:hover::after {
    opacity: 1; /* set opacity to 1 on hover */
  }
}
.anim_acc a img {
  transition: opacity .3s;
}
.anim_acc a img:hover {
  opacity: 0.2;
}


div .generative_acc{
  
  width:25vw;
}


.generative_acc {
  position: relative;
  &::after {
    content: "GENERATIVE ART";
    display: block;
    position: absolute;
    bottom: 0; /* adjust the positioning */
    left: 50%; /* center it horizontally */
    color: white;
    font-family: "Thicccboi";
    font-size: 3rem; /* use a rem unit for better scalability */
    font-weight: bold;
    text-align: center;
    align-items: center;
    transform: translateX(-50%); /* adjust the vertical positioning */
    width: 100%; /* make it full-width */
    height: 100px; /* set a fixed height */
    bottom:28%;
    background-color: transparent; /* add a white overlay */
    opacity: 0; /* set initial opacity to 0 */
    transition:HOTO opacity .3s; /* add transition effect for opacity */
  }
  &:hover::after {
    opacity: 1; /* set opacity to 1 on hover */
  }
}
.generative_acc a img {
  transition: opacity .3s;
}
.generative_acc a img:hover {
  opacity: 0.2;
}


/* Add media query for mobile devices (portrait mode) */
@media (max-width: 1000px) and (orientation: landscape)  {


  div .text-accueil{
    
     font-Size:1rem;
     
   }
   
   .text-accueil{
     
     
     font-Size:1rem;
     
   }
   
   


}





@media (max-width: 820px) {

  div .grid-container {
    display: block;
    flex-direction: column;
    width:100vw;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px; /* add some space between the elements */
    overflow-y: auto;
  }

  div .first{top:1vh;
    position: relative ;
}

  div .pho_acc, div .video_acc, div .anim_acc, div .generative_acc {
    grid-column: 1 / -1; /* take up the full width of the grid column */
    width:100%;
  }


  .pho_acc {
    
     /* make it full-width */

    &::after {
      content: "PHOTOGRAPHIE";
      display: block;
      position: absolute;
      bottom: 0; /* adjust the positioning */
      left: 50%; /* center it horizontally */
      color: white;
      font-family: "Thicccboi";
      font-size: 2rem; /* use a rem unit for better scalability */
      font-weight: bold;
      text-align: center;
      align-items: center;
      transform: translateX(-50%); /* adjust the vertical positioning */
      height: 2rem; /* set a fixed height */
      bottom:10%;
      background-color: transparent; /* add a white overlay */
      opacity: 0; /* set initial opacity to 0 */
      transition: opacity .3s; /* add transition effect for opacity */
    }
    &:hover::after {
      opacity: 1; /* set opacity to 1 on hover */
    }
  }
  .pho_acc a img {
    
    transition: opacity .3s;
  }
  .pho_acc a img:hover {
    opacity: 0.2;
  }
  
 


  .video_acc {
    position: relative;
    &::after {
      content: "FILMS";
      display: block;
      position: absolute;
      bottom: 0; /* adjust the positioning */
      left: 50%; /* center it horizontally */
      color: white;
      font-family: "Thicccboi";
      font-size: 2rem; /* use a rem unit for better scalability */
      font-weight: bold;
      text-align: center;
      align-items: center;
      transform: translateX(-50%); /* adjust the vertical positioning */
      height: 2rem; /* set a fixed height */
      bottom:10%;
      background-color: transparent; /* add a white overlay */
      opacity: 0; /* set initial opacity to 0 */
      transition: opacity .3s; /* add transition effect for opacity */
    }
    &:hover::after {
      opacity: 1; /* set opacity to 1 on hover */
    }
  }
  .video_acc a img {
    
    transition: opacity .3s;
  }
  .video_acc a img:hover {
    opacity: 0.2;
  }
  
 

  .anim_acc {
    position: relative;
    &::after {
      content: "CG ART";
      display: block;
      position: absolute;
      bottom: 0; /* adjust the positioning */
      left: 50%; /* center it horizontally */
      color: white;
      font-family: "Thicccboi";
      font-size: 2rem; /* use a rem unit for better scalability */
      font-weight: bold;
      text-align: center;
      align-items: center;
      transform: translateX(-50%); /* adjust the vertical positioning */
      height: 2rem; /* set a fixed height */
      bottom:10%;
      background-color: transparent; /* add a white overlay */
      opacity: 0; /* set initial opacity to 0 */
      transition: opacity .3s; /* add transition effect for opacity */
    }
    &:hover::after {
      opacity: 1; /* set opacity to 1 on hover */
    }
  }
  .anim_acc a img {
    transition: opacity .3s;
  }
  .anim_acc a img:hover {
    opacity: 0.2;
  }
  
 


  .generative_acc {
    position: relative;
    &::after {
      content: "GENERATIVE ART";
      display: block;
      position: absolute;
      left: 50%; /* center it horizontally */
      color: white;
      font-family: "Thicccboi";
      font-size: 2rem; /* use a rem unit for better scalability */
      font-weight: bold;
      text-align: center;
      align-items: center;
      transform: translateX(-50%); /* adjust the vertical positioning */
      height: 2rem; /* set a fixed height */
      bottom:10%;
      background-color: transparent; /* add a white overlay */
      opacity: 0; /* set initial opacity to 0 */
      transition: opacity .3s; /* add transition effect for opacity */
    }
    &:hover::after {
      opacity: 1; /* set opacity to 1 on hover */
    }
  }
  .generative_acc a img {
    transition: opacity .3s;
  }
  .generative_acc a img:hover {
    opacity: 0.2;
  }




  div .text-accueil{
    position: relative;
     justify-content:space-around;
     align-items: center;
     top:0vh;
     height: 30vh;
     width:70vw;
     font-family: 'Thicccboi';
     font-Size:1rem;
     color:white;
     padding: 5px;
    padding-left:15vw;
    padding-right:15vw;
    
   }
   
   .text-accueil{
     
     justify-content:space-around;
     align-items: center;
     height: 30vh;
     width:100vw;
     font-family: 'Thicccboi';
     font-Size:2rem;
     color:rgb(202, 202, 202);
     padding: 5px;
     text-decoration: none;
   }
   
   .text-accueil a:visited {
     color: rgb(221, 221, 221);
     text-decoration: none;
   }
   
   div .text-accueil a {
     display: relative;
     text-decoration: none;
     font-weight: bold;
   }
  
  
  div .grid-container{
    display: flex;
    
     height: 70vh;
  }
}
  

@media (max-width: 720px) and (orientation: landscape)  {


  div .text-accueil{
    position: relative;
     justify-content:space-around;
     align-items: center;
     top:0vh;
     height: 23vh;
     width:70vw;
     font-family: 'Thicccboi';
     font-Size:1rem;
     color:white;
     padding: 5px;
    padding-left:15vw;
    padding-right:15vw;
    margin-bottom: 5px;
   }
   
   .text-accueil{
     
     justify-content:space-around;
     align-items: center;
     height: 23vh;
     width:98vw;
     font-family: 'Thicccboi';
     font-Size:1rem;
     color:rgb(202, 202, 202);
     padding: 5px;
     text-decoration: none;
     margin-bottom: 5px;
   }
   
   .text-accueil a:visited {
     color: rgb(221, 221, 221);
     text-decoration: none;
   }
   
   div .text-accueil a {
     display: relative;
     text-decoration: none;
     font-weight: bold;
   }


}


@media (max-width: 480px) {


  div .text-accueil{
    position: relative;
     justify-content:space-around;
     align-items: center;
     top:0vh;
     height: 23vh;
     width:70vw;
     font-family: 'Thicccboi';
     font-Size:1rem;
     color:white;
     padding: 5px;
    padding-left:15vw;
    padding-right:15vw;
    margin-bottom: 5px;
   }
   
   .text-accueil{
     
     justify-content:space-around;
     align-items: center;
     height: 23vh;
     width:98vw;
     font-family: 'Thicccboi';
     font-Size:1rem;
     color:rgb(202, 202, 202);
     padding: 5px;
     text-decoration: none;
     margin-bottom: 5px;
   }
   
   .text-accueil a:visited {
     color: rgb(221, 221, 221);
     text-decoration: none;
   }
   
   div .text-accueil a {
     display: relative;
     text-decoration: none;
     font-weight: bold;
   }


}