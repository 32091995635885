.fullscreen{
  /* position: fixed;  Fixe le conteneur sur la page */

  top: 0;

  right: 0;

  bottom: 0;

  left: 0;

 
}

.page {
  width: 100%;
  height:100%;
  position: fixed;
  top:0;
  
  
}

.top-tags {
  bottom:auto;
  
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position:absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
}

.bottom-tag-html {
  margin-left: -20px;
}
