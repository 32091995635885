
html {
  font-size: 62.5%;
  background-color: #000000;
opacity: 1;
background-size: 11px 11px;
background-image: repeating-linear-gradient(45deg, #141414 0, #141414 1.1px, #000000 0, #000000 50%);
}

body {
  margin: 0;
  font: 300 11px/1.4 'Thicccboi', sans-serif;
  color:#444;
  
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


div {
    display:block;
    margin:0;
    
}

.slick-next {

    top:50vh;
    right:20px;
    z-index:9;
}
.slick-prev {
    top:50vh;
    left:20px;

    z-index:9;
}
.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    z-index:9;
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: .45;
    color: white;
    -webkit-font-smoothing: antialiased;
    z-index:9;
}

.slick-prev:after, .slick-next:after {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: .45;
    color: white;
    -webkit-font-smoothing: antialiased;
    z-index:9;
}









/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
    
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.overpho{

  overflow-y: scroll;
  max-height:100vh;

}

::-webkit-scrollbar {
display: none;
}

.player-wrapper {
  
}


@media screen and (max-width: 900px)  {

  .slick-next {

      top:93vh;
      right:15px;
      z-index:9;
  }
  .slick-prev {
      top:93vh;
      left:40px;

      z-index:9;
  }
  .slick-prev, .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      display: block;
      width: 50px;
      height: 50px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
  }

  .slick-prev:before, .slick-next:before {
      font-family: 'slick';
      font-size: 40px;
      line-height: 1;
      opacity: .45;
      color: white;
      -webkit-font-smoothing: antialiased;
      z-index:9;
  }

  .slick-prev:after, .slick-next:after {
      font-family: 'slick';
      font-size: 40px;
      line-height: 1;
      opacity: .45;
      color: white;
      -webkit-font-smoothing: antialiased;
      z-index:9;
  }

  .player-wrapper {
    

  }

}

@media (orientation: landscape) {
  body {
    flex-direction: row;
  }
}

@media (orientation: portrait) {
  body {
    flex-direction: column;
  }
}


